import { DateRangeShortcut } from '@blueprintjs/datetime';
import {
  addWeeks,
  addMonths,
  addYears,
  subWeeks,
  subMonths,
  subYears,
  startOfDay,
  endOfDay,
  isSameDay,
  parseISO,
  format,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  addDays,
  subDays
} from 'date-fns';

import { pluralize } from './strings';

export const DATETIME_24_SHORT_WITH_SECONDS = 'MM/dd/yy at HH:mm:ss';
export const DATETIME_24_SHORT = "MM/dd/yy 'at' HH:mm";
export const DATE_SHORT = 'P';
export const TIME_HOUR_AND_MINUTE = 'HH:mm';
export const ACTIVITY_LOG = "MMMM dd 'at' HH:mm";

const now = () => new Date();
export const todayStart = () => startOfDay(now());
export const todayEnd = () => endOfDay(now());

export const tomorrowStart = () => startOfDay(addDays(todayStart(), 1));
export const tomorrowEnd = () => endOfDay(tomorrowStart());

export const oneWeekAgo = () => subWeeks(todayStart(), 1);
export const oneMonthAgo = () => subMonths(todayStart(), 1);
export const sixMonthsAgo = () => subMonths(todayStart(), 6);
export const tenYearsAgo = () => subYears(todayStart(), 10);

export const oneYearFromNow = () => addYears(todayEnd(), 1);
export const tenYearsFromNow = () => addYears(todayEnd(), 10);

export const yesterdayStart = () => startOfDay(subDays(todayStart(), 1));
export const yesterdayEnd = () => endOfDay(yesterdayStart());

export const oneYearAgo = () => subYears(todayStart(), 1);
export const twoYearsAgo = () => subYears(todayStart(), 2);
export const fiveYearsAgo = () => subYears(todayStart(), 5);

export const oneWeekFromNow = () => addWeeks(todayEnd(), 1);
export const oneMonthFromNow = () => addMonths(todayEnd(), 1);
export const threeMonthsFromNow = () => addMonths(todayEnd(), 3);
export const sixMonthsFromNow = () => addMonths(todayEnd(), 6);
export const twoYearsFromNow = () => addYears(todayEnd(), 2);
export const fiveYearsFromNow = () => addYears(todayEnd(), 5);

export const futureDateShortcuts = (): DateRangeShortcut[] => {
  return [
    { label: 'Tomorrow', dateRange: [tomorrowStart(), tomorrowEnd()] },
    { label: 'Next 7 days', dateRange: [todayStart(), oneWeekFromNow()] },
    { label: 'Next month', dateRange: [todayStart(), oneMonthFromNow()] },
    { label: 'Next 6 months', dateRange: [todayStart(), sixMonthsFromNow()] },
    { label: 'Next 12 months', dateRange: [todayStart(), oneYearFromNow()] },
    { label: 'Next 2 years', dateRange: [todayStart(), twoYearsFromNow()] },
    { label: 'Next 5 years', dateRange: [todayStart(), fiveYearsFromNow()] }
  ];
};

export const pastDateShortcuts = (): DateRangeShortcut[] => {
  return [
    { label: 'Today', dateRange: [todayStart(), todayEnd()] },
    { label: 'Yesterday', dateRange: [yesterdayStart(), yesterdayEnd()] },
    { label: 'Past 7 days', dateRange: [oneWeekAgo(), todayEnd()] },
    { label: 'Past month', dateRange: [oneMonthAgo(), todayEnd()] },
    { label: 'Past 6 months', dateRange: [sixMonthsAgo(), todayEnd()] },
    { label: 'Past 12 months', dateRange: [oneYearAgo(), todayEnd()] },
    { label: 'Past 2 years', dateRange: [twoYearsAgo(), todayEnd()] },
    { label: 'Past 5 years', dateRange: [fiveYearsAgo(), todayEnd()] }
  ];
};

export const mixedDateShortcuts = (): DateRangeShortcut[] => {
  return [
    { label: 'Past 10 years', dateRange: [tenYearsAgo(), todayEnd()] },
    { label: 'Past 5 years', dateRange: [fiveYearsAgo(), todayEnd()] },
    { label: 'Past 12 months', dateRange: [oneYearAgo(), todayEnd()] },
    { label: 'Past week', dateRange: [oneMonthAgo(), todayEnd()] },
    { label: 'Today', dateRange: [todayStart(), oneMonthFromNow()] },
    { label: 'Next 3 months', dateRange: [todayStart(), threeMonthsFromNow()] },
    { label: 'Next 6 months', dateRange: [todayStart(), sixMonthsFromNow()] },
    { label: 'Next 12 months', dateRange: [todayStart(), oneYearFromNow()] },
    { label: 'Next 5 years', dateRange: [todayStart(), fiveYearsFromNow()] }
  ];
};

export const isToday = (isoString: string | number | Date) => {
  const date = typeof isoString === 'string' ? parseISO(isoString) : new Date(isoString);
  return isSameDay(date, new Date());
};

export const relativeTime = (isoString: string) => {
  const date = parseISO(isoString);
  const now = new Date();
  const seconds = differenceInSeconds(now, date);
  const minutes = differenceInMinutes(now, date);
  const hours = differenceInHours(now, date);
  const days = differenceInDays(now, date);

  if (seconds < 60) {
    return `${seconds} ${pluralize('second', 'seconds', seconds)} ago`;
  } else if (minutes < 60) {
    return `${minutes} ${pluralize('minute', 'minutes', minutes)} ago`;
  } else if (hours < 24) {
    return `${hours} ${pluralize('hour', 'hours', hours)} ago`;
  } else {
    return `${days} ${pluralize('day', 'days', days)} ago`;
  }
};

export const formatTime = (isoString: string, formatStr = 'P') => {
  const date = parseISO(isoString);
  try {
    return format(date, formatStr);
  } catch {
    return '';
  }
};

export const timeAgoIn = ({
  unit = 'days',
  startISO
}: {
  unit?: 'days' | 'hours' | 'minutes' | 'seconds';
  startISO: string;
}) => {
  const startDate: Date = parseISO(startISO);
  const now = new Date();

  switch (unit) {
    case 'days':
      return differenceInDays(now, startDate);
    case 'hours':
      return differenceInHours(now, startDate);
    case 'minutes':
      return differenceInMinutes(now, startDate);
    case 'seconds':
      return differenceInSeconds(now, startDate);
    default:
      throw new Error(`Unsupported unit: ${unit}`);
  }
};
